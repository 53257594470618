import { graphql } from "gatsby";
import React from "react";
import Nav from "../components/Nav";
import Img from "gatsby-image";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import SEO from "../components/SEO";

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
`;
const NavStyle = styled.div`
  grid-row: 1;
  grid-column: 1 / 20;
  margin: 10px;
  z-index: 4;
  @media (max-width: 850px) {
    grid-column: 1 / 30;
  }
  @media (max-width: 750px) {
    grid-column: 1 / 30;
  }
  @media (max-width: 650px) {
    grid-column: 1 / 40;
  }
  @media (max-width: 400px) {
    grid-column: 1 / 55;
  }
`;
const TitleCard = styled.div`
  color: var(--grey);
  background-color: #7a0301;
  width: 90%;
  margin: 8px auto;
  z-index: 1;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
`;
const Title = styled.h1`
  font-style: italic;
  font-weight: bold;
  padding: 10px;
`;
const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  grid-template-rows: 0.4fr 0.6fr;
  @media (max-width: 850px) {
    grid-template-rows: 0.4fr 0.5fr 0.6fr;
  }
  @media (max-width: 640px) {
    grid-template-rows: 0.4fr 0.5fr 0.5fr 0.4fr;
  }
  @media (max-width: 560px) {
    grid-template-rows: 0.4fr 250px 0.5fr 0.4fr;
  }
  @media (max-width: 420px) {
    grid-template-rows: 0.4fr 200px 0.5fr 0.4fr;
  }
  @media (max-width: 360px) {
    grid-template-rows: 0.2fr 150px 0.5fr 0.4fr;
  }
`;
const CenterImage = styled.div`
  grid-row: 1;
  grid-column: 20 / 60;
  margin-top: 3rem;
  z-index: -1;
  @media (max-width: 850px) {
    grid-column: 30 / 95;
  }
  @media (max-width: 750px) {
    grid-column: 30 / 98;
    margin-top: 0rem;
  }
  @media (max-width: 640px) {
    grid-column: 10 / 90;
    grid-row: 2;
  }
`;
const PortraitCard = styled.div`
  grid-row: 1;
  grid-column: 62 / 100;
  height: 270px;
  margin-top: 2rem;
  color: var(--grey);
  background-color: #7a0301;
  z-index: 3;
  box-shadow: -30px 46px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 850px) {
    grid-row: 2;
    grid-column: 8 / 49;
    margin-top: 1rem;
  }
  @media (max-width: 640px) {
    grid-column: 40 / 90;
    grid-row: 1;
  }
  @media (max-width: 400px) {
    grid-column: 47 / 98;
  }
`;
const PortraitStyle = styled.div`

  padding: 5px;
  height: 95%;
  width: 95%;
  margin: auto;
  align-items: center;
 display: grid;
 grid-template-columns: 1fr 1fr;
 @media (max-width: 500px) {
  grid-template-columns: 1fr;
  }
`;
const PortraitImage = styled(Image)`
  @media (max-width: 500px) {
    margin: auto;
    height: 140px;
  }
  
`;
const ProtraitText = styled.p`
  text-align: center;
  
  margin: auto 4px;
  font-size: 15px;
`;


const DescriptionCard = styled.div`
  grid-row: 2;
  grid-column: 3 / 63;
  margin-top: 2rem;
  color: var(--grey);
  background-color: #7a0301;
  z-index: 1;
  box-shadow: -30px 46px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 850px) {
    grid-row: 3;
    grid-column: 7 / 93;
  }
  @media (max-width: 640px) {
    margin-top: 0rem;
    grid-row: 3;
    grid-column: 7 / 93;
  }
`;
const ExhibitionsCard = styled.div`
  grid-row: 2;
  grid-column: 65 / 100;
  margin: 40px;
  padding: 20px;
  color: var(--grey);
  background-color: #999999;
  box-shadow: -30px 46px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 850px) {
    margin: 5px 0;
    grid-column: 53 / 93;
  }
  @media (max-width: 640px) {
    margin: 40px 0;
    grid-row: 4;
    grid-column: 15 / 85;
  }
`;
const ExhibitText = styled.h5`
  color: #222222;
  padding: 15px;
`;
const AboutTitle = styled.h2`
  padding: 10px 12px;
`;
const TextStyle = styled.p`
  font-size: 14px;
  padding: 10px 12px;
`;
const ExhibitionsText = styled.h4`
  text-align: center;
  color: #222222;
`;
export default function About({ data }) {
  const aboutPage = data.aboutPage

  return (
    <>
      <SEO title="About" />
      <Wrapper>
        <TitleCard>
          <Title>{aboutPage.title}</Title>
        </TitleCard>
        <StyleGrid>
          <NavStyle>
            <Nav />
          </NavStyle>
          <CenterImage>
            <Image {...aboutPage.centerPainting[0].image} alt="Bottom Image" />
          </CenterImage>
          <PortraitCard>
            <PortraitStyle>
              
              <PortraitImage {...aboutPage.portrait[0].image} alt="Jeff Richards Portrait" />
              <ProtraitText>
                Jeff Richards, standing next to a portrait by Sharon Brown, from
                her series "The Creators".
              </ProtraitText>
            </PortraitStyle>
          </PortraitCard>
          <DescriptionCard>
            <AboutTitle>Jeff Richards - Some of the Story</AboutTitle>
            <TextStyle>
              Standing at the abyss of existential despair and the prospect of a
              mid-life crisis at the tender age of 35, Jeff Richards decided a
              180 degree turn was in order - he sold his cabinetmaking business
              and embarked on a lifelong dream of becoming an artist, taking 7
              years to complete a BA degree in Studio Art at San Francisco State
              University and an MFA degree in Sculpture from the San Francisco
              Art Institute. He then spent the next 7 years unlearning
              everything he’d learned in art school, at which point he began the
              real work.
            </TextStyle>
            <TextStyle>
              There were two major crossroads on that path through the labyrinth
              of meaningful creativity. The first appeared when an artist friend
              approached him with a box of industrial sewing thread spools,
              thrusting it in his hands with the admonition “I’ve had these for
              2 years and I don’t know what to do with them, you give it a
              try!”. Being a sculptor, and having never sewn in his life,
              Richards promptly placed the box on a shelf and forgot about it –
              until one day a voice drew him back to the forgotten container,
              and he began idly playing with the strange material, never
              expecting much to come of it. 2 years later sewing thread was his
              primary medium, proving once again the value of secondary
              processes.
            </TextStyle>
            <TextStyle>
              The second crossroad followed a series of uncanny synchronicities
              - some painful, some magical - over a period of 9 months which,
              woven together into a web of fate, created a catalyst that
              manifested on August 18, 2008. All of the years of work, of
              laborious exploration, of deep mining within the psyche, suddenly
              alchemized and thrust him into creative warp drive. Nothing has
              been the same since.
            </TextStyle>
          </DescriptionCard>
          <ExhibitionsCard>
            <ExhibitionsText>Exhibitions and Events:</ExhibitionsText>
            {aboutPage.exhibitions.map((item, index) => (
              <ExhibitText key={`${item}-${index}`}>
                &#8226;{item.title} - {item.date}
              </ExhibitText>
            ))}
          </ExhibitionsCard>
        </StyleGrid>
      </Wrapper>
    </>
  );
}
export const query = graphql`
  query AboutPageQuery {
    aboutPage: sanityAboutPage {
        title
        exhibitions {
          title
          date
        }
        portrait {
          image {
            ...ImageWithPreview
          }
        }
        centerPainting {
          image {
            ...ImageWithPreview
          }
        }
        bottomPainting {
          image {
            ...ImageWithPreview
          }
        }
    }
  }
`;